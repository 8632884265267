import { Controller } from "stimulus"

export default class extends Controller {
  showAll() {
    let items = document.querySelectorAll('.c-accordion__result__hidden');
    items.forEach((item)=>{
      item.classList.remove('c-accordion__result__hidden');
    });

    items.forEach((item)=>{
      item.classList.remove('c-accordion__result__hidden');
    });

    document.getElementById('show-more-color').style.display = 'none';
  }
}
