// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "core-js/features/object/assign"
import "core-js/features/set"
import "core-js/features/map"
import "core-js/features/promise"
import "core-js/features/array/find"
import "core-js/features/array/from"
import elementClosest from "element-closest"
import "mutation-observer-inner-html-shim"
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import '../stylesheets/app.scss';
import '@glidejs/glide/dist/css/glide.core.min.css';
import Glide from '@glidejs/glide/dist/glide.min.js';
import Choices from 'choices.js';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import Dropzone from "dropzone/dist/dropzone-amd-module";
import 'dropzone/dist/min/dropzone.min.css';
import 'dropzone/dist/min/basic.min.css';
import Pristine from 'pristinejs/dist/pristine.js';
import 'fslightbox';
import ScrollBooster from 'scrollbooster';


elementClosest(window); 

window.Glide = Glide;
window.Pristine = Pristine;

window.ScrollBooster = ScrollBooster;
window.Dropzone = Dropzone;
Dropzone.autoDiscover = false;

window.Choices = Choices;
window.Swiper = Swiper;
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

Rails.start()
ActiveStorage.start()
import "controllers"

window.Rails = Rails;

//new Glide('.glide').mount()